<template>
  <div class="main-container">
    <div class="main-heading">
      <h1>{{ $t("GAME_MODE_DECKS") }}</h1>
      <Button
        :loading="false"
        text="Add"
        type="info"
        :onClick="() => $router.push({ name: 'GameModeDeckAdd' })"
      />
    </div>

    <div class="flex">
      <input
        type="text"
        v-model="search"
        placeholder="Search Bot Decks..."
        class="flex-items"
      />

      <select v-model="eventType" class="flex-items2">
        <option value="1">Challenge</option>
        <option value="2">Random Rumble</option>
        <option value="none">Select Event Type</option>
      </select>

      <select v-model="deckType" class="flex-items2">
        <option value="1">PVP</option>
        <option value="0">COOP</option>
        <option value="none">Select Deck Type</option>
      </select>

      <select v-model="deckStatus" class="flex-items2">
        <option value="1">ENABLED</option>
        <option value="0">DISABLED</option>
        <option value="none">Select Deck Status</option>
      </select>

      <div>
        <multiselect
          v-model="unitValue"
          :options="unitOptions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Units"
          label="name"
          track-by="name"
          :preselect-first="false"
        >
          <template v-slot:selection="{ values, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} units selected</span
            ></template
          >
        </multiselect>
      </div>

      <div>
        <multiselect
          v-model="heroValue"
          :options="heroOptions"
          :multiple="false"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Hero"
          label="name"
          track-by="name"
          :preselect-first="false"
        >
          <template v-slot:selection="{ values, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} hero selected</span
            ></template
          >
        </multiselect>
      </div>

      <button
        class="button"
        @click="
          () =>
            searchBotDeck(
              search.toLowerCase(),
              deckType,
              deckStatus,
              unitValue,
              heroValue,
              eventType
            )
        "
      >
        Search
      </button>
      <button class="button" @click="() => resetFilters(deckType, deckStatus)">
        Reset Filters
      </button>
    </div>

    <div>
      <h4>Filtered Bot Decks: {{ gameModeDecksArrDisplay.length }}</h4>
    </div>

    <div class="content-container">
      <div class="row row-header">
        <div
          v-for="column in columns"
          v-bind:key="column"
          class="cell cell-header"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ $t(column) }}
        </div>
      </div>
      <div
        class="row"
        v-for="gameModeDeck in gameModeDecksArrDisplay"
        v-bind:key="gameModeDeck.id"
        @click="
          () =>
            $router.push({
              name: 'GameModeDeckAdd',
              query: { id: gameModeDeck.id },
            })
        "
      >
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ gameModeDeck.name }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          <input
            type="checkbox"
            :checked="gameModeDeck.status === 1"
            @click="(event) => updateGameModeBotDeckStatus(event, gameModeDeck.id, gameModeDeck)"
          />
        </div>
         <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ botDeckConstants.BOT_LEVEL_VISUAL[gameModeDeck.bot_level.toString()] }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ gameModeDeck.min_win }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ gameModeDeck.max_win }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ getHeroText(gameModeDeck.hero) }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ getUnitText(gameModeDeck.units[0]) }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ getUnitText(gameModeDeck.units[1]) }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ getUnitText(gameModeDeck.units[2]) }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ getUnitText(gameModeDeck.units[3]) }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ getUnitText(gameModeDeck.units[4]) }}
        </div>

        <div class="cell">
          <button
            @click="(event) => copyGameModeDeck(event, gameModeDeck)"
            class="button info"
          >
            <i class="fas fa-copy"></i>
          </button>
          <button
            @click="
              (event) =>
                deleteGameModeDeck(event, gameModeDeck.id, gameModeDeck.index)
            "
            class="button error"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  watch,
  ref,
  computed,
  onBeforeMount,
  onUpdated,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import Multiselect from "vue-multiselect";
import dispatchMap from "@/constants/dispatchMap";
import Button from "../../components/common/Button.vue";

import botDeckConstants from "@/constants/botDeckConstants";
export default {
  name: "GameModeDecks",
  components: {
    Multiselect,
    Button,
  },
  setup: function () {
    const store = useStore();
    const filters = computed(() => store.getters["gameModeDecks/getFilters"]);
    const usedPages = ["gameModeDecks"];
    onBeforeMount(() => {
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => {
          store.dispatch(dispatchStr);
        });
      });
    });
    const units = computed(() => store.getters["units/getUnits"]);
    const heroes = computed(() => store.getters["heroes/getHeroes"]);

    const gameModeDecksArr = [];
    let gameModeDecksArrDisplay = ref([]);
    const sortField = ref("name");
    const search = ref("");
    const deckStatus = ref("none");
    const deckType = ref("none");
    const unitValue = ref([]);
    const heroValue = ref([]);
    const eventType = ref("none");
    const searchBotDeck = (
      search,
      deckType,
      deckStatus,
      unitValue,
      heroValue,
      eventType
    ) => {
      let selectedFilters = {
        search: search,
        deckStatus: deckStatus,
        deckType: deckType,
        unitValue: unitValue,
        heroValue: heroValue,
        eventType: eventType,
      };
      let result = JSON.parse(JSON.stringify(gameModeDecksArr.value));
      result = result.filter((botDeck) => {
        return botDeck.name && botDeck.name.toLowerCase().includes(search);
      });
      result = result.filter((botDeck) => {
        if (deckType === "none") {
          return true;
        } else if (deckType === "1" && botDeck.is_pvp === true) {
          return true;
        } else if (deckType === "0" && botDeck.is_pvp === false) {
          return true;
        } else {
          return false;
        }
      });
      result = result.filter((botDeck) => {
        if (deckStatus === "none") {
          return true;
        } else if (deckStatus === "1" && botDeck.status === 1) {
          return true;
        } else if (deckStatus === "0" && botDeck.status === 0) {
          return true;
        } else {
          return false;
        }
      });
      result = result.filter((botDeck) => {
        return eventType === "none" || eventType == botDeck.event_type;
      });

      result = result.filter((botDeck) => {
        if (unitValue.length === 0) {
          return true;
        } else {
          let units = [];
          for (let i = 0; i < 5; i++) {
            units.push(botDeck.units[i].id);
          }
          for (let i = 0; i < unitValue.length; i++) {
            if (!units.includes(unitValue[i].id)) {
              return false;
            }
          }
          return true;
        }
      });

      result = result.filter((botDeck) => {
        if (!heroValue) {
          return true;
        }
        if ("id" in heroValue) {
          if (heroValue.id === botDeck.hero.id) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      });

      gameModeDecksArrDisplay.value = result;
      store.dispatch("gameModeDecks/setFilters", selectedFilters);
    };

    const applyFilters = () => {
      if (Object.keys(filters.value).length) {
        search.value = filters.value.search;
        deckType.value = filters.value.deckType;
        deckStatus.value = filters.value.deckStatus;
        unitValue.value = filters.value.unitValue;
        heroValue.value = filters.value.heroValue;
        eventType.value = filters.value.eventType;
        searchBotDeck(
          search.value,
          deckType.value,
          deckStatus.value,
          unitValue.value,
          heroValue.value,
          eventType.value
        );
      }
    };
    watch(
      () => store.getters["gameModeDecks/getGameModeDecks"],
      (value) => {
        gameModeDecksArr.value = [...value].sort((a, b) =>
          ("" + a[sortField.value]).localeCompare(b[sortField.value])
        );
        if (!Object.keys(filters.value).length)
          gameModeDecksArrDisplay.value = JSON.parse(
            JSON.stringify(gameModeDecksArr.value)
          );
        else applyFilters();
      },
      { immediate: true }
    );
    const unitOptions = ref([]);
    const heroOptions = ref([]);

    watch(
      () => store.getters["units/getUnits"],
      () => {
        unitOptions.value = units.value
          .filter((x) => x.status === 1)
          .map((unit) => {
            return { id: unit.id, name: unit.name };
          });
      },
      { immediate: true }
    );

    watch(
      () => store.getters["heroes/getHeroes"],
      () => {
        heroOptions.value = heroes.value
          .filter((x) => x.status === 1)
          .map((hero) => {
            return { id: hero.id, name: hero.name };
          });
      },
      { immediate: true }
    );
    function delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    }

    const deleteGameModeDeck = async (event, id, index) => {
      event.stopPropagation();
      store.dispatch("gameModeDecks/deleteGameModeDeck", { id, index });
      await delay(2000);
      gameModeDecksArrDisplay.value = JSON.parse(
        JSON.stringify(gameModeDecksArr.value)
      );
    };

    const numberSort = (a, b) => a[sortField.value] - b[sortField.value];
    const stringSort = (a, b) =>
      ("" + a[sortField.value]).localeCompare(b[sortField.value]);

    const changeSortField = (field, type) => {
      sortField.value = field;
      gameModeDecksArrDisplay.value = gameModeDecksArr.value.sort(
        type === 0 ? stringSort : numberSort
      );
    };

    const copyGameModeDeck = async (event, gameModeDeck) => {
      event.stopPropagation();
      store.dispatch("gameModeDecks/addGameModeDeck", {
        data: {
          ...gameModeDeck,
          name: gameModeDeck.name + "_copy",
          id: undefined,
        },
      });
      await delay(2000); // Await architecture for state mutation requires particular changes, temporary solution
      gameModeDecksArrDisplay.value = JSON.parse(
        JSON.stringify(gameModeDecksArr.value)
      );
    };

    const getHeroText = (hero) => {
      const selectedHero = heroes.value.find((x) => x.id === hero.id);
      return (
        selectedHero.name +
        "/" +
        (hero.level + botDeckConstants.RARITY_LV_MAP[selectedHero.rarity] - 1)
      );
    };

    onUpdated(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    const getUnitText = (unit) => {
      const selectedUnit = units.value.find((x) => x.id === unit.id);
      if (!selectedUnit) console.log(unit);
      return (
        selectedUnit.name +
        "/" +
        (unit.level + botDeckConstants.RARITY_LV_MAP[selectedUnit.rarity] - 1)
      );
    };

    const resetFilters = () => {
      gameModeDecksArrDisplay.value = JSON.parse(
        JSON.stringify(gameModeDecksArr.value)
      );
      search.value = "";
      deckType.value = "none";
      deckStatus.value = "none";
      eventType.value = "none";
      unitValue.value = [];
      heroValue.value = [];
      store.dispatch("gameModeDecks/setFilters", {});
    };

    const appConfig = computed(() => store.getters["auth/getAppConfig"]);
    const env = appConfig.value.appEnv || "dev";
    const exportTo = (environment) => {
      store.dispatch("gameModeDecks/exportAllGameModeDecks", environment);
    };

    const updateGameModeBotDeckStatus = async (event, id, botDeck) => {
      event.stopPropagation();
      const botDeckData = JSON.parse(JSON.stringify(botDeck));
      botDeckData.status == 0
        ? (botDeckData.status = 1)
        : (botDeckData.status = 0);
      const result = await store.dispatch("gameModeDecks/updateGameModeDeck", {
        id: id,
        data: botDeckData,
      });
      if (result) botDeck.status = botDeckData.status;
    };

    return {
      deleteGameModeDeck,
      botDeckConstants,
      changeSortField,
      copyGameModeDeck,
      getUnitText,
      getHeroText,
      gameModeDecksArrDisplay,
      searchBotDeck,
      resetFilters,
      unitOptions,
      heroOptions,
      unitValue,
      heroValue,
      search,
      deckStatus,
      deckType,
      eventType,
      columns: [
        "Name",
        "Status",
        "Difficulty",
        "MinWin",
        "MaxWin",
        "Hero",
        "Unit 1",
        "Unit 2",
        "Unit 3",
        "Unit 4",
        "Unit 5",
        "Actions",
      ],
      exportTo,
      env,
      updateGameModeBotDeckStatus
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-container {
  width: 100%;
}

.table {
  flex-grow: 1;
  max-width: 85vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 5%;
}
.row {
  flex-grow: 1;
  width: 100%;
  display: inline-flex;
  max-height: 80px;
  overflow: hidden;
  align-items: center;
}

.row:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.cell.cell-header {
  font-size: 1.15rem;
  font-weight: 600;
  border-bottom: 1px solid gray;
}

.cell {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 0;
}

.button {
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  color: black;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

.button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

.button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.cell.clickable {
  cursor: pointer;
}

.flex {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-around;
  align-items: center;
}
.flex-items {
  width: 150px;
  height: 30px;
  margin: 5px;
  text-align: center;
}
.flex-items2 {
  width: 150px;
  height: 35px;
  margin: 5px;
  text-align: center;
}
</style>