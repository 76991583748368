export default {
    ARENAS: [
        {
            text: 'Arena 0',
            value: -1
        },
        {
            text: 'Arena 1',
            value: 0
        },
        {
            text: 'Arena 2',
            value: 1
        },
        {
            text: 'Arena 3',
            value: 2
        },
        {
            text: 'Arena 4',
            value: 3
        },
        {
            text: 'Arena 5',
            value: 4
        },
        {
            text: 'Arena 6',
            value: 5
        },
        {
            text: 'League',
            value: 6
        },
    ],
    BOT_LEVEL: [
        {
            text: 'VERY BEGINNER',
            value: -2
        },
        {
            text: 'BEGINNER',
            value: 0
        },
        {
            text: 'PRE_INTER',
            value: 1
        },
        {
            text: 'REGULAR',
            value: 3
        },
        {
            text: 'ADVANCED',
            value: 4
        },
        {
            text: 'VETERAN',
            value: 2
        },
        {
            text: 'HARD_BEGINNER',
            value: 5
        },
        {
            text: 'UBER',
            value: 6
        }
    ],
    BOT_LEVEL_VISUAL: {
        "-2": "Very Beginner",
        "0": "Beginner",
        "1": "Pre Inter",
        "3": "Regular",
        "4": "Advanced",
        "2": "Veteran",
        "5": "Hard Beginner",
        "6": "Uber" 
    },
    RARITY_MAP: {
        0: 'Common',
        1: 'Rare',
        2: 'Epic',
        3: 'Legendary'
    },
    RARITY_LV_MAP: {
        0: 1,
        1: 3,
        2: 5,
        3: 7
    },
    STATUS: [
        {
            text: "DISABLED",
            value: 0
        },
        {
            text: "ENABLED",
            value: 1
        }
    ],
    EVENT_TYPES: [
        {
            text: 'Challenge',
            value: 1
        },
        {
            text: 'Random Rumble',
            value: 2
        }
    ]
}